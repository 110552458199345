/***
    The new CSS reset - version 1.6.0 (last updated 29.4.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly. */
:where([contenteditable]) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable='true']) {
    -webkit-user-drag: element;
}


html {
    font-size: 62.5%;
    width: 100%;
}

body {
    min-width: 320px;
    height: 100vh;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    overflow-x: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a.button-blue {
    width: auto;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 9px;
    color: #ffffff;
    background: #2d69fd;
    font-weight: 500;
    font-size: 20px;
}
a.button-blue:hover {
    background: #2556ce;
}

.container {
    width: 90%;
    max-width: 1172px;
    margin: auto;
}

/******************** top-header ********************/

.top-header {
    background-color: #0b0d11;
    padding-top: 3rem;
    height: 85.5rem;
    position: relative;
    overflow: hidden;
}

.top-header .container {
    position: relative;
    padding-bottom: 1.5rem;
}

.top-header .texts {
    position: absolute;
    z-index: 10;
}

.top-header .image-big-coin {
    display: block;
    width: 561px;
    height: 350px;
    background: url('/landingPage/big-coin-compose-mobile.png') no-repeat;
    background-size: 561px 350px;
    z-index: 3;
    top: 39rem;

    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.top-header .image-multi-coins {
    position: absolute;
    top: 37rem;
    width: 1124px;
    height: 853px;
    filter: blur(1px);
    background: url('/landingPage/multi-coins.png') no-repeat;
    background-size: 42.8rem 30.9rem;
    z-index: 2;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 18rem;
    right: 0;
}

.top-header .shadow-blue {
    position: absolute;
    display: block;
    width: 522px;
    height: 522px;
    left: -261px;
    top: 233px;
    background: #379fff;
    opacity: 0.5;
    filter: blur(200px);
    z-index: 0;
}

.top-header .shadow-yellow {
    position: absolute;
    display: block;
    width: 616px;
    height: 616px;
    left: 395px;
    top: -122px;
    background: #fee609;
    opacity: 0.5;
    filter: blur(200px);
    z-index: 0;
}

.top-header .title-get-jon-gordons,
.top-header .title-first-nft {
    color: #fff;
    font-weight: 500;
    font-size: 3rem;
}

.top-header .title-for-free {
    color: #2d69fd;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 5.5rem;
    margin-top: 0.5rem;
}

.top-header .text {
    color: #e8e8e8;
    padding: 10px 0;
    max-width: 556px;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    line-height: 2.8rem;
}

.top-header a.button-blue {
    margin: 2rem 0;
}

/******************** nfts-include ********************/

.nfts-include .box-white {
    background: #f6f6f7;
    border-radius: 2rem;
    margin-top: -40px;
    padding: 3rem;
    position: relative;
}

.nfts-include .box-white h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.04em;
    color: #0a1d34;
}

.nfts-include .box-white ul {
    max-width: 605px;
    margin-top: 3rem;
}

.nfts-include .box-white > ul > li {
    font-weight: 400;
    font-size: 1.4rem;
    color: #627183;
    display: flex;
    margin-bottom: 2.5rem;
}

.nfts-include .box-white ul > li div:nth-child(1) {
    display: inline-block;
    width: 1px;
    height: auto;
    background: #2d69fd;
    border: 2px solid #2d69fd;
    border-radius: 5px;
    margin-right: 2rem;
}

.nfts-include .box-white ul > li:last-child div:nth-child(1) {
    background: transparent;
    border-color: transparent;
}

/******************** the-sale-book ********************/

.the-sale-book .image-book {
    margin-top: 3rem;
}
.the-sale-book .text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.04em;
    color: #627183;
    padding: 0 2rem 2rem 2rem;
}

.the-sale-book .text p {
    margin-bottom: 2rem;
}

.the-sale-book .button-blue {
    margin: 0 auto;
}

/******************** how-to-get ********************/

.how-to-get .box-white {
    background: rgba(243, 243, 244, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 3rem;
}

.how-to-get .box-white h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.04em;
    color: #0a1d34;
}

.how-to-get .box-white h2 .box-bg-blue {
    color: #fff;
    background: #2d69fd;
    background: #2d69fd;
    /* backdrop-filter: blur(6px); */
    border-radius: 0.8rem;
    transform: rotate(2deg);
    display: inline-block;
    padding: 0.8rem;
}
.how-to-get .box-white h2 .box-bg-blue span {
    transform: rotate(-2deg);
    display: inline-block;
}

.how-to-get .box-white .header p {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #627183;
    margin-top: 3rem;
}

.how-to-get .box-white .steps {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.how-to-get .box-white .steps .step {
    background: rgba(208, 222, 255, 0.55);
    backdrop-filter: blur(8px);
    border-radius: 2rem;
    margin-top: 2rem;
    padding: 2rem 2rem 4rem 2rem;
}

.how-to-get .box-white .steps .step h3 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 57px;
    color: #164783;
}

.how-to-get .box-white .steps .step p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;
    letter-spacing: 0.04rem;
    padding: 3rem;
    color: #627183;
}

.how-to-get .box-white .steps .step .logos {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.how-to-get .box-white .steps .step .button-blue {
    margin: 0 auto;
}

/******************** webinar-replay ********************/

.webinar-replay .box-white {
    background: rgba(243, 243, 244, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 3rem;
}

.webinar-replay .box-white h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.04em;
    color: #0a1d34;
    text-align: center;
}

.webinar-replay .box-white .header {
    text-align: center;
}

.webinar-replay .box-white .header p {
    font-size: 1.2rem;
    line-height: 1.9rem;
    text-align: center;
    letter-spacing: 0.04em;
    color: #627183;
    margin: 2.2rem 0;
}

.webinar-replay .box-white .header a {
    color: #2d69fd;
}

.webinar-replay .box-white .header p strong {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    color: #2d69fd;
}

/******************** what-an-nft ********************/

.what-an-nft {
    color: #fff;
    background-color: #0b0d11;
    padding-top: 3rem;
    /* height: 85.5rem; */
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
}

.what-an-nft .container {
    position: relative;
    padding-bottom: 1.5rem;
}

.what-an-nft .container .header {
    font-weight: 700;
    font-size: 3rem;
}

.what-an-nft .container .header .blue-text {
    color: #2d69fd;
}

.what-an-nft .columns3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    gap: 3rem;
}

.what-an-nft .shadow-blue {
    position: absolute;
    display: block;
    width: 522px;
    height: 522px;
    left: -261px;
    top: 233px;
    background: #379fff;
    opacity: 0.5;
    filter: blur(200px);
    z-index: 0;
}

.what-an-nft .columns3 p {
    margin-bottom: 3rem;
}

.what-an-nft .columns3 p:last-child {
    color: #2d69fd;
}

/******************** roadmap ********************/

.roadmap .box-white {
    background: rgba(243, 243, 244, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 3rem;
}

.roadmap .header {
    display: flex;
    justify-content: center;
}

.roadmap .header h2 {
    position: relative;
    display: inline-block;
}

.roadmap .image-pokemon-coin {
    display: block;
    width: 130px;
    height: 122px;

    background: url('/landingPage/pokemon-coin.png') no-repeat;
    background-size: 100% 100%;

    position: absolute;
    z-index: 10;
    top: -9rem;
    left: -10rem;
}

.roadmap .box-white h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.04em;
    color: #0a1d34;
    text-align: center;
}

.roadmap .box-white h2 .box-bg-blue {
    color: #fff;
    background: #2d69fd;
    border-radius: 0.8rem;
    transform: rotate(2deg);
    display: inline-block;
    padding: 0.8rem;
}
.roadmap .box-white h2 .box-bg-blue span {
    transform: rotate(-2deg);
    display: inline-block;
}

.roadmap ul li {
    margin-top: 3rem;
}

.roadmap ul li div.line {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
}

.roadmap ul li div.line span.number {
    color: #2d69fd;
    font-weight: 700;
    font-size: 2rem;
}

.roadmap ul li div.line span.image-line-gray {
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    height: 0px;
    display: block;
    width: 100%;
}

.roadmap ul li div h3 {
    color: #164783;
    font-weight: 700;
    font-size: 2rem;
    margin: 1rem 0 1rem 0;
}

.roadmap ul li div p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.06rem;
    color: #627183;
    margin-bottom: 1rem;
}

.roadmap ul li div.line span.image-ball-mark-up {
    display: block;
    width: 20px;
    height: 20px;
    background: url('/landingPage/ball-mark-roadmap.svg') no-repeat;
    background-size: 100% 100%;
}

.roadmap ul li div.line span.image-ball-mark-right {
    display: block;
    width: 20px;
    height: 20px;
    background: url('/landingPage/ball-mark-roadmap.svg') no-repeat;
    background-size: 100% 100%;
    transform: rotate(90deg);
}

.roadmap ul li div.line span.image-ball-mark-down {
    display: block;
    width: 20px;
    height: 20px;
    background: url('/landingPage/ball-mark-roadmap.svg') no-repeat;
    background-size: 100% 100%;
    transform: rotate(180deg);
}

.roadmap ul li div.line span.image-ball-mark-left {
    display: block;
    width: 20px;
    height: 20px;
    background: url('/landingPage/ball-mark-roadmap.svg') no-repeat;
    background-size: 100% 100%;
    transform: rotate(270deg);
}

/******************** faq ********************/

.faq .container {
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    padding: 1rem;
}

.faq ul {
    list-style: disc;
    padding: 20px;
}

.faq h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 5rem;
    text-align: center;
    letter-spacing: 0.05rem;
    color: #0a1d34;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* Accordion styles */
.faq input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

/* Accordion styles */
.faq .tabs {
    overflow: hidden;
    border-bottom: 1px solid #f3f3f3;
}

.faq .tab {
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #f3f3f3;
}
.faq .tab-label {
    display: flex;
    padding: 2rem 1rem;
    cursor: pointer;
    color: #0a1d34;
    position: relative;
}
.faq .tab-label span {
    display: block;
    padding-left: 40px;
}

.faq .tab-label::before {
    color: #2d69fd;
    content: '';
    background: url('/landingPage/faq-arrow.svg') no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 36px;
    display: block;
    top: 2rem;

    transition: all 0.2s;
    transform: rotate(-90deg);
    position: absolute;
}
.faq .tab-content {
    max-height: 0;
    margin-left: 2rem;

    transition: all 0.2s;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #627183;
}
.faq .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
}
.faq input:checked + .tab-label::before {
    transform: rotate(0deg);
}
.faq input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
    margin-bottom: 2rem;
}

.faq .faq-need-help {
    text-align: center;
    padding: 20px 0 0 0;
}
.faq .faq-need-help a {
    font-size: 1.6rem !important;
    color: #2d69fd;
}

/******************** about ********************/

.about {
    margin-bottom: 4rem;
}

.about .box-white {
    background: rgba(243, 243, 244, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 3rem;
}

.about .header {
    display: flex;
    flex-direction: column;
    max-width: 551px;
}

.about .header .title {
    margin: 0 auto;
    width: 230px;
}

.about .header .title h2:first-child {
    font-weight: 700;
    font-size: 3rem;
    /* line-height: 90px; */
    /* letter-spacing: 0.05em; */
    color: #0a1d34;
}

.about .header .title h2:last-child {
    font-weight: 500;
    font-size: 3rem;
    color: #2d69fd;
    text-align: right;
}

.about .header p {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.06rem;
    color: #627183;
    margin: 3rem 0;
}

.about div img {
    opacity: 95%;
    border-radius: 40px;
}

.last {
    padding-top: 0.1rem;
}

@media (min-width: 615px) {
    .top-header {
        height: 68.5rem;
    }
    .top-header .image-big-coin {
        width: 841px;
        height: 525px;
        background-size: 100%;
        top: 0;
        position: absolute;
        left: 23rem;
    }

    a.button-blue {
        width: 208px;
    }

    .the-sale-book {
        margin-top: 3rem;
    }

    .the-sale-book .container {
        display: flex;
    }

    .the-sale-book .image-book {
        margin-top: 0;
    }

    .top-header .text {
        font-size: 2.2rem;
        line-height: 3.2rem;
        letter-spacing: 0.03em;
        max-width: 55%;
    }

    .the-sale-book .button-blue {
        margin: 0;
    }

    .how-to-get .box-white .steps {
        flex-direction: row;
        gap: 2rem;
    }

    .how-to-get .box-white .steps .step {
        max-width: 410px;
        width: 300px;
    }

    .webinar-replay .box-white .header p {
        font-size: 1.6rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 450px;
    }

    .webinar-replay .header img {
        margin-top: 1.5rem;
    }

    .webinar-replay .box-white .header p strong {
        font-size: 2.8rem;
    }

    .what-an-nft .container .header {
        display: flex;
        height: 170px;
        justify-content: center;
    }
    .what-an-nft .container .header .image-question-mark {
        display: block;
        width: 259px;
        height: 218px;
        background: url('/landingPage/question-mark.png') no-repeat;
        background-size: 100%;
    }
    .what-an-nft .container .header h2 {
        max-width: 748px;
        width: 500px;
    }

    .what-an-nft .columns3 {
        flex-direction: row;
        align-items: flex-start;
    }
    .what-an-nft .columns3 div {
        max-width: 331px;
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: 0.04rem;
    }

    .roadmap ul li div.line {
        gap: 1.5rem;
    }

    .roadmap ul li div.line span.image-ball-mark-up,
    .roadmap ul li div.line span.image-ball-mark-right,
    .roadmap ul li div.line span.image-ball-mark-down,
    .roadmap ul li div.line span.image-ball-mark-left {
        width: 40px;
        height: 40px;
    }
    .roadmap ul li div h3,
    .roadmap ul li div > p {
        margin-left: 50px;
    }
    .roadmap ul li div h3 {
        margin-top: 0;
        font-size: 2.2rem;
    }
    .roadmap ul li div p {
        margin-top: 25px;
        margin-bottom: 45px;
    }

    .about .box-white {
        display: flex;
        position: relative;
    }

    .about div img {
        /* position: absolute; */
        margin-left: 53px;
        margin-top: 71px;
    }
}

@media (min-width: 615px) {
    .top-header .image-big-coin {
        left: 26rem;
    }
    .the-sale-book .image-book img {
        max-width: 300px;
    }
    .how-to-get .box-white {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (min-width: 689px) {
    .top-header .image-big-coin {
        left: 30rem;
    }
}
@media (min-width: 800px) {
    .top-header .image-big-coin {
        left: 37rem;
    }
}
@media (min-width: 920px) {
    .top-header .image-big-coin {
        left: 44rem;
    }
}
@media (min-width: 1024px) {
    .top-header .image-big-coin {
        left: 48rem;
    }
}

@media (min-width: 1024px) {
    /* .how-to-get .box-white {
      display: flex;
      flex-direction: column;
      align-items: center;
    } */

    .the-sale-book .image-book img {
        max-width: 430px;
    }
    .the-sale-book .text p {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.04em;
    }

    .nfts-include .container {
        position: relative;
    }

    .nfts-include .box-white > ul > li {
        font-size: 1.8rem;
        line-height: 29px;
    }
    .nfts-include .box-white ul > li div:nth-child(1) {
        border: 4px solid #2d69fd;
    }

    .nfts-include .box-white h2 {
        font-size: 5rem;
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
    .nfts-include .box-white {
        padding: 5rem;
    }
    .nfts-include {
        margin-bottom: 5rem;
    }

    .nfts-include .image-cube {
        display: block;
        width: 516px;
        height: 589px;
        background: url('/landingPage/cube-hexagon.png') no-repeat;
        background-size: 100%;
        position: absolute;
        margin-top: -49.5%;
        margin-left: 49%;
        z-index: 1;
    }
    .container {
        width: 90%;
        max-width: 1000px;
        margin: auto;
    }
}

@media (min-width: 1440px) {
    .top-header .title-get-jon-gordons,
    .top-header .title-first-nft {
        font-size: 54px;
        line-height: 64px;
    }

    .about .header p {
        font-size: 18px;
        line-height: 33px;
        letter-spacing: 0.06em;
    }
}

/**** portal **********/

.bodyPortal {
    font-size: 1rem;
}

.nftCards {
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
    justify-content: center;
}

.nftCard {
    width: 350px;
    height: 479px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 23px 25px;
    margin: 0px 0px 56px 0px;
    background-color: #F2F2F3;
}

.text-intro {
    font-weight: 500;
    margin: 60px auto 30px auto;
    padding: 0 90px;
}

.claimCard {
    max-width: 753px;
    width: 100%;
    background-color: #E2E9FC;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 58px;
    padding: 83px;
}

@media (max-width: 768px) {
    .button-logged-email {
        display: none;
    }

    .text-intro {
        font-weight: 500;
        margin: 60px auto;
        padding: 0 10px;

    }

    .claimCard {
        width: 90%;
        border-radius: 20px;
        padding: 43px;
    }
    .nftCard {
        margin-bottom: 10px;
    }
}

.nftBenefitCard {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;
    margin: 5px 0;
    background-color: #F2F2F3;
}
.benefitsScroll {
    height: 50vh;
    overflow: auto;
}

@media (max-width: 768px) {
    .benefitsScroll {
        height: 70vh;
        overflow: auto;
    }
}